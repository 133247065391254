<template>
  <div
    class="misc-wrapper align-items-md-center"
    :class="[
      showError ? 'align-items-center' : !schLoading ? 'align-items-end' : '',
    ]"
  >
    <div
      class="w-100 centered-content"
      :class="{ 'adjust-centered-content': showError }"
    >
      <div v-if="showError" class="w-100 text-center">
        <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
      <template v-else>
        <b-modal
          id="modal-login"
          hide-footer
          hide-header
          centered
          no-close-on-backdrop
          title=""
          size="sm"
        >
          <b-row align-h="center">
            <b-spinner
              class="m-5"
              style="width: 4rem; height: 4rem"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </b-row>
        </b-modal>

        <div v-if="schLoading" class="text-center">
          <b-spinner
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div>
        <template v-else>
          <div class="text-center mb-1">
            <b-img
              v-if="school.logo"
              :src="school.logo"
              style="object-fit: contain"
              fluid
              height="100px"
              width="100px"
            />
          </div>
          <b-card class="mb-0" footer-class="py-1">
            <div v-if="showMsg" style="height: 200px">
              <lottie-animation path="thankyou.json" />
            </div>
            <template v-else>
              <h5 class="font-weight-bolder mb-1">
                Please provide explanation for being {{ statusText }}?
              </h5>
              <!-- style="max-height: 50dvh" -->
              <b-form-textarea
                placeholder="Type your reason here..."
                v-model.trim="noteEntered"
                rows="1"
                max-rows="8"
              ></b-form-textarea>
              <b-row class="mt-2" align-h="center">
                <b-col md="4">
                  <b-button
                    variant="success"
                    :disabled="request"
                    class="btn-icon"
                    block
                    @click="saveNote()"
                  >
                    <b-spinner v-if="request" small type="grow" />
                    <span v-else>Send</span>
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #footer>
              <h6 class="text-center text-muted mb-0">
                Powered by <span class="text-primary">myskool</span>
              </h6>
            </template>
          </b-card>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    LottieAnimation,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async created() {
    // console.log(this.$route.query);
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
    if (
      !this.$route.query.db ||
      !this.$route.query.cID ||
      !this.$route.query.type ||
      !this.$route.query.id
    ) {
      this.showError = true;
    } else {
      this.db = this.$route.query.db;
      this.cID = this.$route.query.cID;
      this.atType = this.$route.query.type;
      this.attID = this.$route.query.id;
      this.LoadData();
    }
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  computed: {
    statusText() {
      if (this.attObj.status) {
        let obj = this.statusOptions.find((el) => el.key == this.attObj.status);
        return obj ? obj.label : "";
      }
      return "";
    },
    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  data() {
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      showError: false,
      showMsg: false,
      request: false,
      db: "",
      cID: "",
      attID: "",
      atType: "",
      typeDec: "",
      attObj: {
        status: "A",
      },
      school: {
        logo: "",
        // logo: "https://cdn.cloudious.net/file-1676144500973-1462800.png",
      },
      schLoading: false,
      noteEntered: "",
      statusOptions: [
        { label: "Absent", key: "A" },
        { label: "Late", key: "Late" },
        { label: "Leave", key: "L" },
        { label: "Short Leave", key: "SL" },
        { label: "Early Departure", key: "ED" },
      ],
    };
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
    }),

    async saveNote() {
      if (this.noteEntered.trim() != "") {
        this.request = true;
        // this.$bvModal.show("modal-login");

        let obj = {
          id: this.attObj.id,
          type: this.typeDec,
          note: this.noteEntered,
        };
        // console.log(obj);
        var status = await this.post({
          url: this.$store.state.domain + "attendance/updateNote?db=" + this.db,
          body: obj,
          context: this,
          // token: this.token,
          importing: true,
        });
        this.request = false;
        // this.$bvModal.hide("modal-login");
        // console.log(status);
        if (status) {
          this.showMsg = true;
        }
      }
    },
    async LoadData() {
      this.schLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "attendance/InitNote?db=" +
          this.db +
          "&cID=" +
          this.cID +
          "&type=" +
          this.atType +
          "&id=" +
          this.attID,
        // token: this.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      // console.log(res);
      if (!Array.isArray(res) && res.status == "success") {
        this.school = res.data.campus;
        this.typeDec = res.data.type;
        this.attObj = res.data.att;
        if (this.attObj.meta_notes || this.attObj.status == "P") {
          this.showMsg = true;
        }
        // console.log(this.typeDec, this.attObj);
      } else this.showError = true;

      this.schLoading = false;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
<style scoped>
.adjust-centered-content {
  max-width: 450px;
}
@media (min-width: 768px) {
  .centered-content {
    max-width: 450px;
  }
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
